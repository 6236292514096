<script>
  import { onMount } from "svelte";
  import { link } from "svelte-routing";
  import { getCookie } from "utils/tools.js";
  import { apiFetch } from "utils/tools.js"
  import SearchForm from "../../utils/SearchForm.svelte";

  let projectAll;
  let items;
  let teams;
  let auth;
  let beforeAuth;
  let switchingActive = 0;
  let sortingActive = null; 
  let keywordsActive = null;
  let teamActive = null;




  const admins = [
    { position: "manager_state", auth:"7" },
    { position: "leader_state", auth:"6" },
    { position: "assets_leader", auth:"5" },
    { position: "management_leader", auth:"4" },
    { position: "general", auth:"3" },
    { position: "coo", auth:"2" },
    { position: "ceo", auth:"1" },
    { position: "admin", auth:"0" },
  ]
  onMount(async()=>{
    projectAll = await apiFetch("project/all")
    items = projectAll;
    teams = await apiFetch("team");
    console.log('userInfo', userInfo)
  })

  const userInfo = JSON.parse(getCookie("user"))
  
  admins.map((item)=>{
    if(item.auth == userInfo.auth){
      auth = item.position
    }
    if(item.auth == parseInt(userInfo.auth) + 1){
      beforeAuth = item.position
    }
    if(userInfo.auth == 3 ){
      beforeAuth = "leader_state"
    }
  })

  const handelSwitchButton = (setType) => {
    switchingActive = setType
    toalSearch()
  }

  const handleSortingButton = (sortType) => {
    sortingActive = sortType; 
    items = [...items].sort((a, b) => {
      let dateA;
      let dateB;

      if (a.latest_calculate_date !== null) {
        dateA = new Date(a.latest_calculate_date)
      } else {
        dateA = new Date(a.calculate_date)
      }

      if (b.latest_calculate_date !== null) {
        dateB = new Date(b.latest_calculate_date)
      } else {
        dateB = new Date(b.calculate_date)
      }
      
      if (sortType === 'asc') {
        return dateB - dateA
      } else {
        return dateA - dateB
      }
    })

  };
  
  const keywordSearch = (keyword) => {
    keywordsActive = keyword
    toalSearch()
  }

  const teamSearch = (team) => {
    teamActive = team
    toalSearch()
  }


  const toalSearch = () => {
    items = []
    if (switchingActive == 0){
      items = projectAll;
    } 
    projectAll.map(item => {

      if (switchingActive == 1) {
        if(item.calculate_date !== null){
          if (userInfo.auth == 5 || userInfo.auth == 4) { //경영지원팀장, 자산구매팀장
            if(item[auth] == 0) {
              items = [...items, item]
            }
          } else {
            if(item[auth] == 0 && item[beforeAuth] != 0) {
              items = [...items, item]
            }
          }
        }
      };

      if (switchingActive == 2) {
        if(item.calculate_date !== null){
          if(item[auth] == 0 && item[beforeAuth] == 0) {
            items = [...items, item]
          }
        }
      };

      if (switchingActive == 3) {
        if(item.calculate_date == null || item.ceo !== 0){
          items = [...items, item]
        }
      };
      
      if (switchingActive == 4) {
        if(item.calculate_date !== null){
          if(item[auth] != 0) {
            items = [...items, item]
          }
        }
      }
    })


    if (teamActive && teamActive != '') {
      items = items.filter(item => item.team_id == Number(teamActive))
    }

    if (keywordsActive && keywordsActive != '') {
      
      if (items.length > 0) {
        items = items.filter(item => item.name.includes(keywordsActive))  
      }
    }
    if (sortingActive) {
      handleSortingButton(sortingActive)
    }
    

  }

</script>
<div class="switching-tap mb-5"> 
  <div class="search-group" style="display: flex;">
    <div class="btn-group" style="display: flex;">
      <div
        on:click={()=>{handelSwitchButton(0)}} 
        class="team-button {switchingActive == 0 ? "bg-blueGray-700 text-white" : "bg-white text-blueGray-700"} active:bg-blueGray-600 outline-none focus:outline-none shadow hover:shadow-lg ease-linear transition-all duration-150">
        전체
      </div>
      <div 
        on:click={()=>{handelSwitchButton(1)}} 
        class="team-button {switchingActive == 1 ? "bg-blueGray-700 text-white" : "bg-white text-blueGray-700"} active:bg-blueGray-600 outline-none focus:outline-none shadow hover:shadow-lg ease-linear transition-all duration-150">
        결재
      </div>
      {#if userInfo.auth != 5 && userInfo.auth != 4 && userInfo.auth != 7}
      <div 
        on:click={()=>{handelSwitchButton(2)}} 
        class="team-button {switchingActive == 2 ? "bg-blueGray-700 text-white" : "bg-white text-blueGray-700"} active:bg-blueGray-600 outline-none focus:outline-none shadow hover:shadow-lg ease-linear transition-all duration-150">
        대기
      </div>
      {/if}
      {#if userInfo.auth != 1}
      <div 
        on:click={()=>{handelSwitchButton(3)}} 
        class="team-button {switchingActive == 3 ? "bg-blueGray-700 text-white" : "bg-white text-blueGray-700"} active:bg-blueGray-600 outline-none focus:outline-none shadow hover:shadow-lg ease-linear transition-all duration-150">
        등록
      </div>
      {/if}
      <div
        on:click={()=>{handelSwitchButton(4)}} 
        class="team-button {switchingActive == 4 ? "bg-blueGray-700 text-white" : "bg-white text-blueGray-700"} active:bg-blueGray-600 outline-none focus:outline-none shadow hover:shadow-lg ease-linear transition-all duration-150">
        완료
      </div>
    </div>
    <SearchForm {keywordSearch} {teamSearch} {teams} {userInfo}/>
    <button
      type="button"
      on:click={() => handleSortingButton('asc')}
      class="team-button {sortingActive === 'asc' ? 'bg-blueGray-700 text-white' : 'bg-white text-blueGray-700'} active:bg-blueGray-600 outline-none focus:outline-none shadow hover:shadow-lg ease-linear transition-all duration-150"
    >
      오름차순
    </button>
    <button
      type="button"
      on:click={() => handleSortingButton('desc')}
      class="team-button {sortingActive === 'desc' ? 'bg-blueGray-700 text-white' : 'bg-white text-blueGray-700'} active:bg-blueGray-600 outline-none focus:outline-none shadow hover:shadow-lg ease-linear transition-all duration-150"
    >
      내림차순
    </button>
  </div>
</div>
<div class="project-info-wrap" style="overflow: hidden;">
  <table  class="project-info" style="width: 100%;">
    <thead class="project-info-header">
      <tr>
        <th>구분</th>
        <th>팀</th>
        <th>프로젝트</th>
        <th>정산일</th>
        <th class="progress-bar">결재</th>
        <!-- <th>이동</th> -->
      </tr>
    </thead>
    <tbody class="project-info-body">
        {#if !items}
          <tr>
            <td colspan="6">프로젝트가 없습니다.</td>
          </tr>
        {:else}
          {#each items as item, index}
            {#if userInfo.auth < 6}
              <tr style="background:{index%2 == 0 ? null : "#f8f8f8"};">
                <td>{item.division == 1 ? "직영" : "외주"}</td>
                <td style="color:{item.team_name == null ? "red" : "unset"};">{item.team_name == null ? "팀 없음" : item.team_name}</td>
                <td>
                  <a href="/project/{item.id}" class="management-detail" use:link>{item.name}</a>
                </td>
                <td>
                  {#if item.latest_calculate_date !== null}
                    {item.latest_calculate_date}
                  {:else}
                    {#if item.calculate_date !== null}
                      {item.calculate_date}
                    {:else}
                      -
                    {/if}
                  {/if}
                </td>
                <td
                  class="progress-bar border-t-0 px-6  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style="align-items: center; width: 30%"
                >
                {#if item.calculate_date !== null}
                  {#if item.ceo == 0}
                    {#if item[auth] == 0 }
                      {#if userInfo.auth == 4 || userInfo.auth == 5 || userInfo.auth == 7 || item[beforeAuth] !== 0}
                        <span style="color: #fd7265;">결재가 필요합니다.</span>
                      {:else}
                        <span style="color: #999;">결재 대기중입니다.</span>
                      {/if}

                    {:else if auth == "admin"}
                      <span style="color: #6577fd;">관리자는 결재를 할 수 없습니다.</span>
                    {:else}
                      <span style="color: #2f44e4;">결재를 완료했습니다.</span>
                    {/if}
                  {:else}
                    {#if userInfo.auth == 1}
                      <span style="color: #2f44e4;">결재를 완료했습니다.</span>
                    {:else if auth == "admin"}
                      <span style="color: #6577fd;">관리자는 결재를 할 수 없습니다.</span>
                    {:else}
                      <span style="color: #505896;">결재를 완료했습니다.<br>추가 결재를 등록해주세요.</span>
                    {/if}
                  {/if}
                {:else}
                  <span>결재를 등록해주세요.</span>
                {/if}
                </td>
              </tr>
            {:else}
              {#each item.user_id as userId, index}
                <!-- {#if userId == userInfo.id || userInfo.auth < 6} -->
                {#if userId == userInfo.id}
                  <tr style="background:{index%2 == 0 ? null : "#f8f8f8"};">
                    <td>{item.division == 1 ? "직영" : "외주"}</td>
                    <td style="color:{item.team_name == null ? "red" : "unset"};">{item.team_name == null ? "팀 없음" : item.team_name}</td>
                    <td>
                      <a href="/project/{item.id}" class="management-detail" use:link>{item.name}</a>
                    </td>
                    <td>
                      {#if item.latest_calculate_date !== null}
                        {item.latest_calculate_date}
                      {:else}
                        {#if item.calculate_date !== null}
                          {item.calculate_date}
                        {:else}
                          -
                        {/if}
                      {/if}
                    </td>
                    <td
                      class="progress-bar border-t-0 px-6  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" style="align-items: center; width: 30%"
                    >
                    {#if item.calculate_date !== null}
                      {#if item.ceo == 0}
                        {#if item[auth] == 0 }
                          {#if userInfo.auth == 4 || userInfo.auth == 5 || userInfo.auth == 7 || item[beforeAuth] !== 0}
                            <span style="color: #fd7265;">결재가 필요합니다.</span>
                          {:else}
                            <span style="color: #999;">결재 대기중입니다.</span>
                          {/if}

                        {:else if auth == "admin"}
                          <span style="color: #6577fd;">관리자는 결재를 할 수 없습니다.</span>
                        {:else}
                          <span style="color: #2f44e4;">결재를 완료했습니다.</span>
                        {/if}
                      {:else}
                        {#if userInfo.auth == 1}
                          <span style="color: #2f44e4;">결재를 완료했습니다.</span>
                        {:else if auth == "admin"}
                          <span style="color: #6577fd;">관리자는 결재를 할 수 없습니다.</span>
                        {:else}
                          <span style="color: #505896;">결재를 완료했습니다.<br>추가 결재를 등록해주세요.</span>
                        {/if}
                      {/if}
                    {:else}
                      <span>결재를 등록해주세요.</span>
                    {/if}
                    </td>
                  </tr>
                {/if}
              {/each}
            {/if}
          {/each}
        {/if}
    </tbody>
  </table>
</div>
