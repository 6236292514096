<script>
export let keywordSearch;
export let teamSearch;
export let teams;
export let userInfo;

let keyword = "";
let team = "";
</script>

<form class="search-form" 
    on:submit|preventDefault={()=>{keywordSearch(keyword)}} 
    style="display: flex;
        flex-wrap: wrap;
        align-items: center;"
    >
    {#if userInfo}
        {#if teams && userInfo.auth != '7'}
        <select id="select"
            bind:value={team}
            on:change={() => {teamSearch(team)}}
            style="
                border-radius: 4px;
                border: 1px solid;
                padding: 0.3rem 2rem 0.3rem 1rem;
                margin-left: 1rem;
                margin-right: 0.5rem;"
        >
        <option value="">전체</option>
        {#each teams as team}
            <option value={team.id}>{team.name}</option>
        {/each}
        </select>
        {/if}
    {/if}
    <div class="search-form__search" 
        style="display: flex;
        align-items: center;
        overflow: hidden;
        border-radius: 4px;"
    >
        <div class="search-form__search__wrap input-search"
            style=""
        >
            <input id="searchInput" type="text" placeholder="검색어를 입력하세요." bind:value={keyword}
                style="
                width: 200px;
                padding: .3rem 1rem;
                border-radius: 4px 0 0 4px;
                border-right: none;
                font-size: .85rem;
                background: #ffffff !important;
                border: 1px solid;
                transition: text-indent .3s;"
            >
            <button type="button" class="search-form__search__wrap__button"
                on:click={() => {
                    keywordSearch(keyword)
                }}
                style="
                    padding: .36rem 1rem;
                    font-size: .85rem;
                    border-radius: 0 4px 4px 0;
                    min-width: fit-content;
                    background: #44bcc2;
                    border: 1px solid #44bcc2;
                    text-align: center;
                    cursor: pointer;
                    color: #fff;
                    transition: filter .3s;"
            >검색</button>
        </div>
    </div>
</form>
